import React from 'react';
import config from '../config';


const Location = () => (
    <div id="location">
        <div className="main row">
            <div className="col-md-6">
                <h2 className="brown font-weight-600">
                    OUR
                </h2>
                <h3 className="mb-4">
                    ADDRESS
                </h3>
                <div className="dark-brown font-19 font-weight-600 mb-4">
                    We are associated with the South Vancouver Medical Clinic.
                </div>
                <div className="dark-brown font-19 font-weight-600 mb-4">
                    We are on the 2nd floor of the Superstore at 350 SE Marine Drive at Main Street.
                </div>
                <div className="mb-4">
                    <div className="red font-18 font-weight-600">
                        Email: <a href="mailto:info@southvanmed.com">info@southvanmed.com</a>
                    </div>
                    <div className="red font-18 font-weight-600">
                        Phone: 604-323-0077
                    </div>
                    <div className="dark-gray font-18 font-weight-600">
                    DriverMedicalExam.com
                    </div>
                    <div className="dark-gray font-18 font-weight-600">
                        South Vancouver Medical Clinic
                    </div>
                    <div className="dark-gray font-18 font-weight-600">
                        350 Southeast Marine Drive, Vancouver, BC,
                    </div>
                    <div className="dark-gray font-18 font-weight-600">
                        Canada V5X 2S5
                     </div>
                </div>
            </div>
            <div className="col-md-6 text-center">
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2606.55186848907!2d-123.10224158431322!3d49.2090601793233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54867450af2276a1%3A0x24f20a1082450ef1!2s350+SE+Marine+Dr%2C+Vancouver%2C+BC+V5X+2S5!5e0!3m2!1sen!2sca!4v1566010948126!5m2!1sen!2sca"
                    width="100%" height="350" frameBorder="0" title="map">
                </iframe>
                <a className="btn btn-lg btn-danger mt-4"
                    href= { config.southVanLogin}
                    target="_blank" rel="noopener noreferrer">
                    Book your phone on in-person appointment here
                </a>
            </div>
        </div>
    </div>
)


export { Location };