import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Routes from './Routes';
import { Navbar, Footer } from './components';


function App() {
    const history = createBrowserHistory();
    
    return (
        <div id="app">
            <Router history={history}>
                <div className="container">
                    <Navbar />
                    <main role="main">
                        <Routes />
                    </main>
                    <Footer />
                </div>
            </Router>
        </div>
    );
}

export default App;
