import React from 'react';


const About = () => (
    <div id="about">
        <div className="top">
            <h2 className="brown font-weight-600">
                OUR
            </h2>
            <h2>
                MISSION
            </h2>
            <div className="brown">
                We want to be the medical clinic you turn to for all your motor vehicle crash needs.
                We realize that having a clinic that is dedicated to ICBC related issues is important to you.
            </div>
            <br />
            <div className="brown">
                We want nothing more than to get you back to health. Whether you have neck pain, back pain or other ailments, going with your day can be bothersome.
                We want help you back to your usual routine as soon possible. But we will stay with you as long as necessary, given that your ICBC claim remains open.
            </div>
        </div>
        <div className="row main">
            <div className="col-md-6">
                <h2 className="brown font-weight-600">
                    YOUR PARTNER IN RECOVERY
                </h2>
                <h2>
                    GOOD MEDICINE
                </h2>
                <div className="dark-brown">
                    We’re committed to providing you with the highest level of service.
                </div>
                <br />
                <div className="dark-brown">
                    For most people, seeing a doctor is not convenient. So, we want make the process as easy and enjoyable as possible for you.
                    We genuinely care about your health and your experience, and it shows.
                    Our first time clients become our long-term clients time and time again, choosing us for all their healthcare needs.
                    We’re committed to great healthcare and to serving you with quality.
                </div>
            </div>
            <div className="col-md-6">
            </div>
        </div>
    </div>
)


export { About };