
import React from 'react';


const Footer = () => (
    <div id="footer" >
        <div>

        </div>
        <div>
            <span className="mr-4">
            DriverMedicalExam.com © 2022
            </span>
            {/* <a className="mr-4">
                Privacy Policy
            </a> */}
        </div>
    </div>
)

export { Footer }
