import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { Home, About, Service, Location } from './pages';



const Routes = () => (
    <Switch>
        <Route exact path='/' component={Home} />
        <Route exact path='/about' component={About} />
        <Route exact path='/service' component={Service} />
        <Route exact path='/contact' component={Location} />

        <Redirect to="/" />
    </Switch>
);

export default Routes;