import { env } from './helpers/environment';

const dev = {
    southVanHost: 'http://localhost:6060',
    southVanLogin: 'http://localhost:6060/login',

}


const test = {
    southVanHost:'https://test.southvanmed.com',
    southVanLogin:'https://test.southvanmed.com/login',

}

const prod = {
    southVanHost:'https://www.southvanmed.com',
    southVanLogin:'https://www.southvanmed.com/login',
}


const config = {
    dev, test, prod
}

export default config[env()];