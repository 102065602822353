import React from 'react';

import { NavLink } from 'react-router-dom';
import config from '../../src/config';


const Home = () => (
    <div id="home">
        <div className="top bg-gradient p-5 text-light rounded">
            <h2 className="mb-3 text-dark">
                WELCOME TO <NavLink to='/' className="text-dark">DriverMedicalExam.com</NavLink> {process.env.REACT_APP_ENV == "test" && "(test)"}
            </h2>
            <div className="description">
            To help keep British Columbia’s roads safe, the South Vancouver Medical Clinic assists RoadSafetyBC to assess the medical fitness of drivers.
            </div>
            <div className="description">
            The assessment tool we use for this is the Driver’s Medical Examination Report. This report is available to you at your local ICBC driver licensing office. Examination reports help determine if a driver’s medical condition affects their ability to drive safely. A driver’s nurse and doctor completes the report. 
            </div>
        </div>
        <div className="main row">
            <div className="top col-md-7 col-sm-12 bg-warning bg-gradient text-light p-5 rounded">

                <div className="description">
                You may need an examination report based on:
                </div>

                <div className="row description">
                    <div className="col-md-12">
                        <ul>
                            <li>
                            Age (80+)
                            </li>
                            <li>
                            Licence class (commercial drivers)
                            </li>
                            <li>
                            A known medical condition (for example diabetes)   
                            </li>
                            <li>
                            If RoadSafety BC has received a report that you have a condition that affects your ability to drive safely.
                            </li>
                        </ul>
                    </div>

                </div>
            </div>
            <div className="col-md-5 clinic-hours col-sm-12">
                <img className='w-100 h-100 rounded col-sm-12' src={require('../assets/img/tool2.jpg')}></img>

            </div>
        </div>
        <div className="top mb-5 bg-success bg-gradient p-5 text-light rounded">

            <div className="description">
            When booked online, Driver’s Medical Examination Report are available to you at special rate of $140. The walk-in regular price is $200. 
            </div>
            <div className="description">
            Be sure to bring your glasses or wear contact lenses for the eye examination part of the Driver’s Medical Examination Report.
            </div>
            <div className="description">
            Also bring a paper copy of your Driver’s Medical Examination Report for the nurse and doctor to fill out. We cannot complete your assessment without this document, which can be obtained from your local ICBC office.
            </div>
        </div>
        <div className="bottom">

            <div className="py-5">
                <a className="btn btn-primary btn-lg"
                    href={ config.southVanLogin }
                    target="_blank" rel="noopener noreferrer">
                    Book driver's medical examination
                </a>
            </div>
        </div>
    </div>
)



export { Home };